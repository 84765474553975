import React, { Component } from 'react'
//import axios from "axios";

import Header from './Header';
import Footer from './Footer';
import Table from 'react-bootstrap/Table'

export class AuthorizedDealers extends Component {
    constructor(props) {    
        super(props);    
        this.state = {
            users: [],
            loading:true
          }
        
    }

    async getUsersData(){
        const res = [
              {
                "id": 1,
                "Dealer_Name": "Chordia Infotech",
                "Dealer_Hq": "Churu",
                "State_Assigned":"Rajasthan",
                "Districts_Assigned":"Churu/Bikaner/Sikar/ Jhunjhunu/SriGanganagar/ Hanumangarh",
                "Contact_Person": "Vinod Kumar/ Puneet Chordia",
                "Mobile_1": "9799855860",
                "Mobile_2": "9414894278",
                "Email_1": "chordiaschooleasy@gmail.com",
                "Email_2": "puneet_mcc@yahoo.in",
              },
              
              {
                "id": 2,
                "Dealer_Name": "Swarn S Deora",
                "Dealer_Hq": "Jaipur",
                "State_Assigned":"Rajasthan",
                "Districts_Assigned":"Jaipur",
                "Contact_Person": "Swarn S Deora",
                "Mobile_1": "9828615143",
                "Mobile_2": "",
                "Email_1": "deoraswarn@gmail.com",
                "Email_2": "",
              },
              {
                "id": 3,
                "Dealer_Name": "Ambe Learning Pvt. Ltd",
                "Dealer_Hq": "Jaipur",
                "State_Assigned":"Rajasthan",
                "Districts_Assigned":"All districts (except Churu)",
                "Contact_Person": "Bahadur Singh Shekhawat",
                "Mobile_1": "9414038063",
                "Mobile_2": "9649901102",
                "Email_1": "md@ambelearning.co.in",
                "Email_2": "helpdesk@ambelearning.co.in",
              },
        ]
        //console.log(res)
        this.setState({loading:false, users: res})
      }
      componentDidMount(){
        this.getUsersData()
      } 
    render() {
        
        //console.log(this.state.users);
        let authorizedDetails =[];
        let s_no = 1;
        let authorized = this.state.users;
        if (authorized.length) {
            authorizedDetails = authorized.map((authorized) => {
              
              return (
                <tr>
                    <td>{s_no++}</td>
                    <td>{authorized.Dealer_Name}</td>	
                    <td>{authorized.Dealer_Hq}</td>
                    <td>{authorized.State_Assigned}</td>
                    <td>{authorized.Districts_Assigned}</td>
                    <td>{authorized.Contact_Person}</td>
                    <td>{authorized.Mobile_1}</td>
                    <td>{authorized.Mobile_2}</td>
                    <td>{authorized.Email_1}</td>
                    <td>{authorized.Email_2}</td>
                </tr>
              );
            });
          }
        return ( 
            <div>
      	    <Header/>
              <div className="container pb-5">
                    <section className="guest_opinion pt-5">
                        <h3 className="text-center pb-4 font-weight-bold title">authorized Dealers</h3>
                        <div class="row table-responsive" id="dealer">
                        <Table striped className="table dealer-table-bordered">
                        <thead><tr>
                            <th><strong>S.No.</strong></th>
                            <th><strong>Dealer Name</strong></th>
                            <th><strong>Dealer HQ</strong></th>
                            <th><strong>State Assigned</strong></th>
                            <th><strong>Districts Assigned</strong></th>
                            <th><strong>Contact Person</strong></th>
                            <th><strong>Mobile 1</strong></th>
                            <th><strong>Mobile 1</strong></th>
                            <th><strong>Email 1</strong></th>
                            <th><strong>Email 2</strong></th>
                            </tr></thead>
                            <tbody>{authorizedDetails} </tbody>
                           </Table>
                        </div>
                        
                        
                        
                    </section>
                </div>


            <Footer />

            </div>
        )
    }
}

export default AuthorizedDealers
