import React, { Component } from 'react'
//import axios from "axios";

import Header from './Header';
import Footer from './Footer';
import Table from 'react-bootstrap/Table'

export class Client extends Component {
    constructor(props) {    
        super(props);    
        this.state = {
            users: [],
            loading:true
          }
        
    }

    async getUsersData(){
        const res = [
              {
                "id": 1,
                "SchoolEasy_Clients": "SOFTTECH SCHOOL, JODHPUR",
                "District": "Jodhpur",
                "Type":"School",
                "Website":"http://softtechschool.online/",
              },
              {
                "id": 2,
                "SchoolEasy_Clients": "ORANGE COUNTY SCHOOL, RAJSAMAND",
                "District": "Rajsamand",
                "Type":"School",
                "Website":"http://www.orangecountyschool.in/",
              },
              {
                "id": 3,
                "SchoolEasy_Clients": "MAHATMA GANDHI MAHAVIDHALAYA, BARMER",
                "District": "Barmer",
                "Type":"School",
                "Website":"mgcollegegida.com",
              },
              {
                "id": 4,
                "SchoolEasy_Clients": "SMS School, Jaipur",
                "District": "Jaipur",
                "Type":"School",
                "Website":"www.msmsvjaipur.com",
              },
              {
                "id": 5,
                "SchoolEasy_Clients": "APC College, Pratapgarh",
                "District": "Pratapgarh",
                "Type":"School",
                "Website":"www.apcpratapgarh.com",
              },
              {
                "id": 6,
                "SchoolEasy_Clients": "Pratap Public Sr. Sec. School, Balawala",
                "District": "Jaipur",
                "Type":"School",
                "Website":"http://www.pratapschool.in/",
              },
              {
                "id": 7,
                "SchoolEasy_Clients": "Jaipur Public School, Taranagar",
                "District": "Churu",
                "Type":"School",
                "Website":"www.jpstaranagar.com",
              },
              {
                "id": 8,
                "SchoolEasy_Clients": "Mohta Public School, Churu",
                "District": "Churu",
                "Type":"School",
                "Website":"",
                
              },
              {
                "id": 9,
                "SchoolEasy_Clients": "Cambridge Convent School",
                "District": "Churu",
                "Type":"School",
                "Website":"",
                
              },
              {
                "id": 10,
                "SchoolEasy_Clients": "Lords International School, Taranagar",
                "District": "Churu",
                "Type":"School",
                "Website":"",
                "Plan":"Basic",
              },
              {
                "id": 11,
                "SchoolEasy_Clients": "Shri Vaishnav Sr. Sec. Shiksha Sadan",
                "District": "Jodhpur",
                "Type":"School",
                "Website":"www.shrivaishnavschool.com",
                "Plan":"Basic",
              },
              {
                "id": 12,
                "SchoolEasy_Clients": "Lords International School, Churu",
                "District": "Churu",
                "Type":"School",
                "Website":"",
                "Plan":"Basic",
              },
              {
                "id": 13,
                "SchoolEasy_Clients": "SHIV NARAYAN INTERNATIONAL SCHOOL, NADBAI",
                "District": "Bharatpur",
                "Type":"School",
                "Website":"",
                "Plan":"Basic",
              },
              {
                "id": 14,
                "SchoolEasy_Clients": "Takshashila High School",
                "District": "MP",
                "Type":"School",
                "Website":"takshashilahighschool.in",
                
              },
              {
                "id": 15,
                "SchoolEasy_Clients": "Lords International School, Sadulpur",
                "District": "Churu",
                "Type":"School",
                "Website":"",
                "Plan":"Basic",
              },
              {
                "id": 16,
                "SchoolEasy_Clients": "Holy Cross Public School",
                "District": "Pali",
                "Type":"School",
                "Website":"",
              },
              {
                "id": 17,
                "SchoolEasy_Clients": "Saraswati Shikshan Sansthan, Nokha",
                "District": "Bikaner",
                "Type":"School",
                "Website":"",
              },
              {
                "id": 18,
                "SchoolEasy_Clients": "L B MEMORIAL SR SEC SCHOOL PALLU ",
                "District": "Hanumangarh",
                "Type":"School",
                "Website":"",
              },
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
        ]
        //console.log(res)
        this.setState({loading:false, users: res})
      }
      componentDidMount(){
        this.getUsersData()
      } 
    render() {
        
        //console.log(this.state.users);
        let schoolDetails =[];
        let s_no = 1;
        let authorized = this.state.users;
        if (authorized.length) {
            schoolDetails = authorized.map((authorized) => {
              
              return (
                <tr>
                    <td>{authorized.id}</td>
                    <td>{authorized.SchoolEasy_Clients}</td>	
                    <td>{authorized.District}</td>
                    <td>{authorized.Type}</td>
                    <td>{authorized.Website}</td>
                </tr>
              );
            });
          }
        return ( 
            <div>
      	    <Header/>
              <div className="container pb-5">
                    <section className="guest_opinion pt-5">
                        <h3 className="text-center pb-4 font-weight-bold title">Client List</h3>
                        <div class="row table-responsive" id="dealer">
                        <Table striped className="table dealer-table-bordered">
                        <thead><tr>
                            <th><strong>S.No.</strong></th>
                            <th><strong>SchoolEasy Client name</strong></th>
                            <th><strong>District</strong></th>
                            <th><strong>Type</strong></th>
                            <th><strong>Website</strong></th>
                            </tr></thead>
                            <tbody>{schoolDetails} </tbody>
                           </Table>
                        </div>
                        
                        
                        
                    </section>
                </div>


            <Footer />

            </div>
        )
    }
}

export default Client
